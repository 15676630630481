import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PoliticasService } from 'src/app/servicios/politicas.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
})
export class PoliticasComponent implements OnInit, AfterViewInit {

  @ViewChild('contenedorPolitica') contenedorHTML: ElementRef;

  htmlContent: any | null = null;
  
  constructor(
    private route: ActivatedRoute, 
    private _politicasService: PoliticasService, 
    private sanitizer: DomSanitizer,
    private _renderer: Renderer2
  ) {}

  //#region lifecycle hooks
  ngOnInit(): void {
    let url = this.route.snapshot.data['url'];
    let recursos = environment.recursos + 'politicas_condiciones/'
    // Aquí carga el HTML desde la ruta especificada
    this._politicasService.loadHtml(recursos + url) .subscribe({
      next: (data: string) => {
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(data)
        this._politicasService.loadCss( recursos + 'politicasCondiciones.css')
        this._politicasService.loadJs(recursos + 'condiciones.js')
      },    
    }); 
  }

  ngAfterViewInit():void{
    const hijo = document.getElementsByClassName('boton-subir')[0];
    this._renderer.removeChild(this.contenedorHTML, hijo);
  }
  //#endregion
}

